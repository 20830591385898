.pricing-wrapper{
    display:flex;
    column-gap: 22px;
}

.price-plan{
    display: flex;
    text-align: center;
    position:relative;
    flex-direction: column;
    row-gap: 30px;
    -webkit-box-align: stretch;
    align-items: stretch;
    line-height: normal;
    width: 100%;
    margin-left: 0px;
    border:2px solid $teal;
    border-radius: 20px;
    padding:40px 24px;
    justify-content: space-between;
}
.price-plan h2{
    font-size: 2rem;
    line-height: 2rem;
}
.price-plan h3{
    margin-top: auto;
    text-wrap:nowrap;
    line-height: 1em;
}

.price-plan h4{
    position:absolute;
    top: 20px;
    left:0px;
    width:100%;
    margin:0px;
}
.price-plan p{
    margin:0px;
    padding:0px;
}

.price-plan.teal{
    background-color:$teal;
    color:white;
    width: 100%;
}
.price-plan.orange{
    background-color:$dot-orange-border;
    color:white;
    width: 100%;
    border-color:$dot-orange-border;
}
.price-plan .checklist{
    margin: -10px 0px 0px;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-bottom:auto;
}
.price-plan .checklist li{
    border:none;
    width:auto;
    text-wrap:nowrap;
}
.price-plan .checklist li:before{
    top: 13px;
}
.smaller-text{
    font-size: 0.6em;
    display:block;
    margin-top: 5px;
}
//responsive styles

@media (max-width: 1300px) {
    .pricing-wrapper{
        flex-wrap:wrap;
        row-gap:24px;
    }
    .price-plan{
        width:calc((100% - 48px)/3);
    }
    .price-plan.teal, .price-plan.orange{
        width: calc((100% - 24px)/2);
        align-items: center;
    }
    .price-plan .checklist{
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        width: 370px;
    }
    .price-plan.teal p{
        max-width: 370px;
    }
    .price-plan .checklist li{
        width:50%;
    }
    
    .price-plan.teal .button-outline-white, .price-plan.orange .button-outline-white{
        width: 260px;
    }
    .smaller-text{
        display:inline;
        margin-top:0px;
    }
}

@media (max-width: 1200px) {
    .price-plan{
        padding-top:40px;
        padding-bottom:40px;
        row-gap: 20px;
    }
    .price-plan h2{
        font-weight: 700;
        font-size: 2rem;
        line-height: 2.75rem;
    }
    .price-plan h3{
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.6rem;
    }
    .price-plan h4{
        display:none;
    }
}


@media (max-width: 1100px) {
    .price-plan .button-solid, .price-plan .button-outline-white{
        padding-left: 15px;
        padding-right: 15px;
    }
    
}

@media (max-width: 991px) {

    .pricing-wrapper{
        flex-direction:row;
        flex-wrap: wrap;
        row-gap:24px;
    }
    
    .price-plan, .price-plan.teal, .price-plan.orange{
        width:100%;
        flex-wrap:wrap;
        flex-direction:row;
        justify-content: space-between;
        align-items: center;
        row-gap: 0px;
        column-gap: 20px;
    }
    .price-plan h2, .price-plan h3{
        width:auto;
        margin:0px auto 0px 0px;
    }
    .price-plan h3{
        margin:0px;
    }
    .smaller-text{
        display:inline;
        margin:0px;
    }
    .price-plan .button-solid, .price-plan .button-outline-white{
        width:auto;
        margin:0px;
    }
    .price-plan.teal .button-outline-white, .price-plan.orange .button-outline-white{
        width: auto;
    }
    .price-plan p, .price-plan .checklist{
        order:4;
        width:80%;
        text-align:left;
        column-gap:24px;
        justify-content:left;
        margin-top:0px;
    }
    .price-plan.teal p {
        max-width: none;
    }

    .price-plan .checklist li {
        width: auto;
    }

}


@media (max-width: 640px) {

    .price-plan, .price-plan.teal, .price-plan.orange{
        width: 100%;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        row-gap: 20px;
        column-gap: 20px;
    }
    .price-plan h2{
        width:100%;
        text-align: center;
    }
    .price-plan h3{
        width:auto;
    }
    .price-plan .button-solid, .price-plan .button-outline-white{
        width:auto;
        margin:0px;
    }

    .price-plan p, .price-plan .checklist{
        order:0;
        width:100%;
        text-align:center;
    }

}
@media (max-width: 480px) {
    .price-plan h3{
        width:100%;
    }
    .price-plan .checklist{
        flex-direction: column;
    }
}