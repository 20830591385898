//footer menu

footer h4{
    padding: 5px 8px;
    margin:0px;
}

footer .column a{
    display: flex;
    flex-direction: row;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    margin-top: 0px;
    line-height: normal;
    height: auto;
    color: rgb(0, 0, 0);
    font-size: 14px;
    cursor: pointer;
    pointer-events: auto;
    font-family: Poppins, sans-serif;
    padding: 5px 8px;
    border-radius: 4px;
    text-decoration: none;
}
footer .column a img{
    height:20px;
    width: 20px;
    margin-right:6px;
}

footer .column a:hover{
    background-color: #1DAFAF1b
}

//copyright

.copyright{
    background-color: $teal;
    color:white;
    text-align: center;
    padding-bottom:80px;
}
.copyright .logo{
    margin:0px auto;
    padding:0px;
}
.copyright p{
    flex-direction: row;
    column-gap: 5px;
    width: auto;
    margin: 0px auto;
    padding: 20px 0px;
}
.cookies{
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    max-width: 650px;
    margin:0px auto;
}
.cookies img{
    height:30px;
    width:auto;
}
.cookies p{
    display: inline-block;
    margin: 0px;
    padding: 0px;
    text-align: left;
    font-size:0.8rem;
    line-height:1.5em;
}
.cookies a{
    color:white;
    font-weight:bold;
    text-decoration:none;
}

//responsive styles

@media (max-width: 1200px) {
}


@media (max-width: 1100px) {
}

@media (max-width: 991px) {

    footer .column-wrapper{
        flex-direction: row;
        flex-wrap: wrap;
    }

    footer .column, footer .column + .column{
        width:50%;
        margin: 0px auto 40px;
    }

}


@media (max-width: 640px) {
}
@media (max-width: 480px) {
}