html{
    font-size:17px;
}

body{
    width:100vw;
    font-family: 'Poppins';
    font-size:18px;
}

section{
    width:100vw;
    display: flex;
    flex-direction: column;
    position:relative;
    box-sizing:border-box;
    padding-left: 44px;
    padding-right: 44px;
    padding-top: 80px;
    padding-bottom: 0px;
}
.section-inner{
    width: 100%;
    align-self: stretch;
    flex-grow: 1;
    box-sizing: border-box;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-left: auto;
    margin-right: auto;
}

.benefits{
    padding-top: 0px;
}

.column-wrapper{
    display:flex;
    margin-bottom:81px;
}

.column{
    display: flex;
    flex-direction: column;
    -webkit-box-align: stretch;
    align-items: stretch;
    line-height: normal;
    width: calc(50% - 27px);
    margin-left: 0px;
}
.column + .column{
    margin-left: 54px;
}

.section-content{
    display: flex;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
}

.section-image{
    display: flex;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    justify-content: center;
}
.section-image img{
    max-width:100%;
}
.image-bg{
    border-radius:20px;
    background-size: cover;
    background-position: center;
}

.checklist{
padding:0px;
margin:20px 0px;
}

.checklist li{
    display: flex;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    line-height: 1.75rem;
    height: auto;
    font-size: 1rem;
    width: 100%;
    max-width: 450px;
    z-index: 2;
    text-align: left;
    font-family: Poppins, sans-serif;
    margin-bottom: 0px;
    border-style: solid;
    border-width: 1px 0px;
    border-color: rgba(190, 190, 190, 0.4);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
}
.checklist li:before{
    width:20px;
    height:20px;
    content: '';
    position:absolute;
    top:13px;
    left:0px;
    background-image: url(/assets/images/tick.svg);
    background-size:contain;
}
.checklist li + li{
    border-width: 0px 0px 1px 0px;
}

//buttons

.button-solid, .button-outline, .button-outline-white{
    display: flex;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    margin-top: 0px;
    appearance: none;
    padding: 15px 35px;
    color: white;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    border-style: solid;
    border-width: 2px;
    border-color: $dot-orange-border;
    font-size: 1rem;
    line-height: 1rem;
    background-color: $dot-orange-border;
    text-decoration: none;
    text-wrap: nowrap;
}

.button-solid.dark-teal{
    background-color: #168B8B;
    border-color: #168B8B;
    
}
.button-solid:hover{
    background-color:$dot-orange;
    border-color:$dot-orange;
}


.button-outline{
    color: $teal;
    border-color: $teal;
    background-color:transparent;
}

.button-outline-white{
    color: white;
    border-color: white;
    background-color:transparent;
}

.button-outline:hover, .button-outline-white:hover{
    color: $dot-orange;
    border-color:$dot-orange;
}

//text styles

h1, h2, h3, h4{
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    height: auto;
    color: inherit;
}
ul, ol{
    max-width:900px;
    width:100%;
    margin:0px auto;
    padding:0px 0px 0px 15px;
}
p, li{
    font-weight: 400;
    position: relative;
    box-sizing: border-box;
    line-height: 1.75rem;
    height: auto;
    font-size: 1rem;
    width: 100%;
    z-index: 2;
    font-family: Poppins, sans-serif;
    margin-bottom: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
}


h1, h2, h3{
    margin: 0px auto;
    line-height: 4rem;
    font-family: Poppins, sans-serif;
    font-size: 3rem;
    font-weight: 700;
    width: 100%;
    max-width: 900px;
}
h3{
    line-height: 2.6rem;
    font-size: 2rem;
    font-weight: 700;
}

h3 a{
    text-decoration:none;
    color:inherit;
}

h4{
    margin-top: 20px;
    line-height: normal;
    height: auto;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: $teal;
    text-transform: uppercase;
    width:100%;
}

//hero

.hero-content{
    max-width: 900px;
    text-align:center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
}

.hero-content strong{
    color: $dot-orange-border;
}

.btn-holder{
    display: flex;
    flex-direction: row;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    margin: 25px 0px;
    -webkit-box-pack: start;
    justify-content: center;
    width: 100%;
    gap: 41px;
}

.section-content .btn-holder{
    justify-content: flex-start;
}

.hero-image{
    margin-top: 81px;
}

.hero-image img{
 max-width: 90%;
}


.grey-bg{
    background-color: $bg-gray;
    text-align:center;
    padding-top: 80px;
    padding-bottom: 80px;
}
.centered{
    text-align:center;
}

//testimonals

.testimonial{
    background-color: $bg-gray;
    text-align:center;
    padding-top: 80px;
    padding-bottom: 80px;
    text-align:left;
}
.quote-logo{
    display: flex;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    overflow: hidden;
    height: 60px;
    width:auto;
    align-self: flex-start;

}
.quote{
    display: flex;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    margin-top: 30px;
    line-height: 2.25rem;
    height: auto;
    font-size: 1.25rem;
    font-weight: 400;
    padding:0px;
}
.credit{
    display: flex;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    margin-top: 10px;
    line-height: 1rem;
    height: auto;
    font-size: 1rem;
    font-weight: 800;
    padding:0px;
}

//cta

.cta{
    background-color: $teal;
    color:white;
    text-align: center;
    padding-bottom:80px;
}
.cta .button-outline{
    border-color:white;
    color:white;
}
.cta .button-outline:hover{
    color: $dot-orange;
    border-color:$dot-orange;
}

.cta strong{
    color:$dot-orange;
}

//responsive styles

@media (max-width: 1200px) {
}


@media (max-width: 1100px) {
}

@media (max-width: 991px) {

    .column-wrapper{
        flex-direction: column;
    }
    .column, .grey-bg .section-inner{
        max-width:650px;
        width:100%;
        margin: 0px auto;
    }
    .column + .column{
        width:100%;
        margin: 0px auto;
    }
    .column.section-image{
        order:2;
        margin: 54px auto 0px;
        min-height: 300px;
    }

    .checklist li{
        max-width:650px;
    }

    h2{
        font-weight: 700;
        font-size: 2rem;
        line-height: 2.75rem;
        max-width: 700px;
    }
    .column h2{
        text-align: left;
    }

    h3{
        line-height: 2.2rem;
        font-size: 1.6rem;
        font-weight: 700;
    }

    

}


@media (max-width: 640px) {

    section{
        padding-top: 60px
    }
    .cta, .copyright{
        padding-bottom:60px;
    }

    h1{
        font-size: 2rem;
        line-height: 2.8rem;
        max-width: 450px;
        margin-top: 0px;
    }
    h2{
        font-size: 1.5rem;
        line-height: 1.8rem;
        margin-top: 0px;
        margin-bottom: 20px;
    }
    p, .checklist li, .quote{
        font-size: 0.9rem;
        line-height: 1.5rem;
    }
    .button-solid, .button-outline{
        font-size: 0.9rem;
        line-height: 0.9rem;
        width: 280px;
    }
    .btn-holder{
        flex-direction: column;
        align-items: center;
        gap: 22px;
    }

}
@media (max-width: 480px) {
    section{
        padding: 40px 22px 0px;
    }
    .cta, .copyright{
        padding-bottom:40px;
    }

}