header{
    display: flex;
    flex-direction: column;
    position: sticky;
    flex-shrink: 0;
    box-sizing: border-box;
    background-color: $teal;
    width: 100vw;
    margin-left: calc(50% - 50vw);
    justify-content: center;
    align-items: center;
    padding-left: 44px;
    padding-right: 44px;
    padding-top: 0px;
    padding-bottom: 0px;
    top: 0px;
    z-index:998;
}
.header-inner{
    display: flex;
    flex-direction: row;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    width: 100%;
    max-width: 1200px;
    justify-content: space-between;
}

.logo{
    display: flex;
    padding-top: 25px;
    padding-bottom: 25px;
    cursor: pointer;
    pointer-events: auto;
    margin-right:auto;
}
.logo img{
    height:30px;
    width: auto;
}
.mobile-logo{
    display: none;
}


.nav-holder{
    display: flex;
    flex-direction: row;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    width:100%;
}

input#menu{
    display:none;
}

.mobile-nav-toggle{
    display:none;
    width: auto;
    height: auto;
    align-self: stretch;
    margin-left: 0px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-grow: 0;
    padding-left: 20px;
    padding-right: 20px;
}
.nav-icon{
    display: flex;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    gap: 7px;
}
.nav-icon-line{
    display: flex;
    flex-direction: row;
    transition: transform .3s cubic-bezier(.37,.01,0,.98);
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    transform: translateY(0px);
}
.nav-icon-line .part1{
    display: flex;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    margin-left: auto;
    width: 10px;
    align-self: stretch;
    height: 2px;
    background-color: rgba(255, 255, 255, 1);
    margin-right: auto;
    transform-origin: right;
    transition: transform .3s cubic-bezier(.37,.01,0,.98);
    transform: rotate(0deg);
}
.nav-icon-line .part2{
    display: flex;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    margin-left: auto;
    width: 10px;
    align-self: stretch;
    height: 2px;
    background-color: rgba(255, 255, 255, 1);
    margin-right: auto;
    transform-origin: left;
    transition: transform .3s cubic-bezier(.37,.01,0,.98);
    transform: rotate(0deg);
}

.nav-icon-middle-line{
    display: flex;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    margin-left: auto;
    width: 20px;
    align-self: stretch;
    height: 2px;
    background-color: rgba(255, 255, 255, 1);
    margin-right: auto;
    transition: max-width .2s cubic-bezier(.37,.01,0,.98);
}

input:checked +label .nav-icon-middle-line {
    max-width: 0px;
}

input:checked +label .nav-icon-line:first-of-type {
    transform: translateY(9px);
}
input:checked +label .nav-icon-line:first-of-type .part1{
    transform: rotate(45deg);
}
input:checked +label .nav-icon-line:first-of-type .part2{
    transform: rotate(-45deg);
}
input:checked +label .nav-icon-line:last-of-type {
    transform: translateY(-8px);
}
input:checked +label .nav-icon-line:last-of-type .part1{
    transform: rotate(-45deg);
}
input:checked +label .nav-icon-line:last-of-type .part2{
    transform: rotate(45deg);
}

.nav-content{
    display: flex;
    flex-direction: row;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
}

.nav-item{
    display: flex;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    justify-content: center;
    border-style: solid;
    border-width: 0px 0px 3px 0px;
    border-color: $teal;
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
    cursor: pointer;
    gap: 0px;
    align-items: center;
    padding: 0px 12px;
}
.nav-item:hover{
    border-color: $dot-orange-border;
}

.nav-buttons{
    display: flex;
    flex-direction: row;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    column-gap: 24px;
    margin: 0px 24px 0px 12px;
}
.nav-buttons .button-outline{
    border-color:white;
    color:white;
}
.nav-buttons .button-outline:hover{
    color: $dot-orange;
    border-color:$dot-orange;
}


.language{
    display: flex;
    flex-direction: row;
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    cursor:pointer;
    order: 3;
}
input#lang-menu{
    display:none
}
.language-menu-toggle{
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5px;
    cursor:pointer;
    height:100%;
}
.flag-icon{
    max-height:20px;
}
.current-language{
    text-transform: uppercase;
    color:white;
    font-weight:400;
    letter-spacing: 1.5px;
    line-height:1em;
    text-wrap: nowrap;
    flex-shrink: 0;
}

.lang-options{
   position:absolute;
   top:calc(100% - 15px);
   right:0px;
   background-color:white;
   width:200px;
   display: none;
    flex-direction: column;
    align-items: left;
    z-index:999;
    border:1px solid $dot-gray;
}
.lang-options a{
    padding: 12px 24px;
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5px;
    text-decoration: none;
    color:$dot-orange-border;
 }
input#lang-menu:checked +label +.lang-options{
    display:flex;
}



//responsive styles

@media (max-width: 1200px) {
}


@media (max-width: 1100px) {
    .nav-holder .button-outline, .nav-holder .button-solid{
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (max-width: 991px) {
    
    .nav-holder{
        flex-wrap:wrap
    }
    .nav-holder .logo .mobile-logo{
        display: none;
    }

    .mobile-nav-toggle{
        display:flex;
    }
    .nav-content{
        display:none;
        width:100%;
        flex-direction:column;
        justify-content:stretch;
        max-height:0px;
        overflow:hidden;
        margin-bottom:44px;
        transition: max-height .5s cubic-bezier(.37,.01,0,.98);
        
    }
    .language {
        order:0;
    }


    input:checked +label+.nav-content{
        display:flex;
        max-height:400px;
        transition: max-height .5s cubic-bezier(.37,.01,0,.98);
    }

    .nav-item{
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 15px;
        padding-right: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;
        border-style: solid;
        border-width: 1px 0px 0px 0px;
        border-color: $teal;
    }
    .nav-item + .nav-item{
        border-style: solid;
        border-width: 1px 0px 0px 0px;
        border-color: #168B8B;
    }
    .nav-item:hover{
        border-color: $teal;
        color: $dot-orange;
    }
    .nav-item + .nav-item:hover{
        border-color: #168B8B;
    }
    .nav-buttons{
        width:100%;
        flex-direction:column;
        justify-content:stretch;
        margin:0px;
    }
    .nav-buttons .button-outline, .nav-buttons .button-solid{
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 15px;
        padding-right: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;
        border-style: solid;
        border-width: 1px 0px 0px 0px;
        border-radius:0px;
        border-color: #168B8B;
        background-color: transparent;
    }
    .nav-buttons .button-outline:hover, .nav-buttons .button-solid:hover{
        border-color: #168B8B;
        background-color: transparent;
    }

}


@media (max-width: 640px) {

    .nav-holder .logo img{
        display:none;
    }
    .nav-holder .logo .mobile-logo{
        display:block;
    }

}
@media (max-width: 480px) {
}