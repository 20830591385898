.hero-stats{
 display:flex;
 column-gap: 22px;
 align-items: center;
 justify-content: center;
 margin:40px auto 81px;
}

.hero-stats img{
    height:40px;
    width:auto;
    flex:none;
}
.hero-stats p{
    margin:0px;
    padding:0px;
    width: 310px;
    font-weight:400;
    display:inline-block;
}

//brands
.brands{
    background-color: $bg-gray;
    text-align:center;
    padding: 30px 0px;
    overflow: hidden; /* Hide anything that goes beyond the bounds of the container */
    width: 100%;
    height: 110px;
}
.brand-logos{
    display: flex;
    width: calc(100% * 2); /* Make sure the container is wide enough to hold two sets of images */
    animation: scroll-left 20s linear infinite;
    justify-content: space-between;
}
.brand-logos img{
    height:30px;
    margin: 10px 20px;
    width: auto;
    flex-shrink: 0;
}

@keyframes scroll-left {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-50%); /* Scroll left by half the total width of the container */
    }
  }

//responsive styles

@media (max-width: 1200px) {
    
}


@media (max-width: 1100px) {
    
}

@media (max-width: 991px) {
    

}


@media (max-width: 600px) {
  .hero-stats{
    flex-direction: column;
    gap: 20px;
    margin:40px auto 61px;
   }
   
   .hero-stats p{
       text-align: center;
   }
}
@media (max-width: 480px) {
    
}