$dot-yellow: #ffff33;
$dot-yellow-border: #ffcc00;
$dot-red: #fd9589;
$dot-red-border: #fd3c2e;
$dot-orange: #fed640;
$dot-orange-border: #fd9602;
$dot-green: #7dff98;
$dot-green-border: #4bda64;
$dot-pink: #d48cd5;
$dot-pink-border: #9f469e;
$dot-blue: #4cc8fe;
$dot-blue-border: #007bff;
$dot-gray: #aeafb0;
$dot-gray-border: #848586;

$teal: #1DAFAF;
$dark-teal: #168B8B;
$bg-gray: #F5F5F5;

$dot-logo: $dot-yellow;
$dot-unobserved: $dot-yellow;
$dot-occupied: $dot-red;
$dot-signs-of-life: $dot-orange;
$dot-unoccupied: $dot-green;
$dot-vacant: $dot-pink;
$dot-unusable: $dot-blue;
$dot-unobservable: $dot-gray;


.dot-yellow {
  background-color: $dot-yellow;
  border: 5px solid $dot-yellow-border;
}
