.card-grid {
  display: flex;
  flex-direction: row;
  text-align: left;
  gap: 2rem;
  flex-wrap: wrap;
  padding-bottom: 4rem;
  padding-top: 2rem;
}

.industries .card-grid{
  padding-top: 0rem;
  border-bottom: 1px solid #e0e0e0;
}
.departments .card-grid{
  padding-top: 0rem;
}

.card {
  display: flex;
  flex-direction: column;
  width: calc((100% - 4rem)/3);
  gap: 1rem;
}

.card-image{
  height: 180px;
  width: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
}

.card-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  h3 {
    font-size: 20px;
    font-weight:600;
    margin: 0rem;
    line-height: 1.5;
  }

  p {
    font-size: 16px;
    margin: 0;
    opacity: 0.9;
    line-height: 1.5;
    padding: 0;
  }
  a.learn-more{
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    color: $dot-orange-border;
  }
  .card-icon{
    lord-icon{
      width: 6rem;
      height: 6rem;
      margin: -0.75rem;
    }
  }
} 
.card-content.centered{
  align-items: center;
  text-align: center;
}

@media (max-width: 640px) {
  .card-grid{
    padding-top: 0px;
    margin-top: -4rem;
  }
  .card-image{
    height: 240px;
  }

  .card{
      width:100%;
  }

}