.blog-hero{
    background-image: url(/assets/images/blog-hero.svg);
    background-position: center top;
    padding-top: 550px;
    background-size: 1360px;
    background-repeat: no-repeat;
}
   
//posts list

.posts-holder{
    display:flex;
    flex-direction: column;
    row-gap: 0px;
    margin-bottom:81px;
}

.post{
    width: 100%;
    display:flex;
    flex-direction:row;
    column-gap: 24px;
    padding:48px 0px;
}

.post + .post {
    border-top:2px solid $bg-gray;
}

.post-image{
    width:50%;
    background-size:cover;
    background-position: center center;
    min-height:100px;
    border-radius: 20px;
}

.post-summary{
    width:50%;
    display:flex;
    flex-direction:column;
    row-gap: 24px;
    align-items: flex-start;
}


.post p{
    margin-top:0px;
    padding:0px;
}

.post .button-solid{
    width:auto;
}
.blog-post{
    padding-bottom:81px;
}
.blog-post blockquote{
    padding:24px;
    background-color:$bg-gray;
    width:100%;
    max-width: 900px;
    margin:20px auto;
}
.blog-post blockquote p{
    border-left: 4px solid black;
    padding-left: 24px;
}

.blog-post p, .blog-post h4{
    max-width:900px;
    margin: 0px auto;
}
.blog-post h2{
    margin: 2rem auto 0px;
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 700;
}
.blog-post h3{
    font-size: 1.2rem;
    line-height: 2rem;
}
.blog-post h4{
    font-size: 1rem;
    line-height: 1.6rem;
}

.blog-post h3, .blog-post h4{
    margin: 2rem auto 0px;
}
.blog-post .post-image{
    width:100%;
    min-height:600px;
    margin-bottom:60px;
}

.filters {
    margin-bottom: 20px;
    display:flex;
    justify-content: center;
}

.filters label {
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    background-color: #ccc;
    color: white;
    cursor: pointer;
    margin-right: 5px;
    transition: background-color 0.3s;
}

#all:checked ~ .filters label[for="all"],
#articles:checked ~ .filters label[for="articles"],
#guides:checked ~ .filters label[for="guides"],
#interviews:checked ~ .filters label[for="interviews"] {
    background-color: $dot-orange-border;
}

.filters + .posts-holder .post {
    display: none;
}

#all:checked ~ .posts-holder .post,
#articles:checked ~ .posts-holder .post.article,
#guides:checked ~ .posts-holder .post.guide,
#interviews:checked ~ .posts-holder .post.interview {
    display: flex;
}


.two-column{
    display:flex;
    flex-direction:row;
    gap: 24px;
    max-width: 900px;
    margin: -40px auto 0px;
    width: 100%;
    justify-content: center;
}

.two-column .column1{
    width:50%;
}

.two-column .column2{
    width:50%;
}

.blog-post table + p, .blog-post .two-column .column1 div + p, .blog-post .two-column .column2 div + p{
    margin-bottom: 24px;
    font-size:x-small;
    color:gray;
    width:100%;
    text-align: center;
    margin:0px auto 16px;
    font-style: italic;
}

// Table styles
.blog-post table {
    max-width: 900px;
    width: 100%;
    margin: 24px auto 0px;
    border-collapse: collapse;
}

.blog-post table th,
.blog-post table td {
    border: 1px solid #ccc;
    padding: 1rem;
}

.blog-post table th {
    background-color: $bg-gray;
    font-weight: 600;
}

.blog-post table td:first-child,
.blog-post table th:first-child {
    text-align: left;
}

.blog-post table td:not(:first-child),
.blog-post table th:not(:first-child) {
    text-align: center;
}

.blog-post .info{
    border-left: 3px solid grey;
    max-width: 900px;
    width:100%;
    margin: 24px auto;
    padding-left: 24px;
    opacity: 0.6;
}

   
//responsive styles

@media (max-width: 1200px) {
}

@media (max-width: 1100px) {
}

@media (max-width: 991px) {
    .blog-post .post-image{
        min-height:400px;
    }
}

@media (max-width: 780px) {

    .post{
        flex-direction: column;
        row-gap: 24px;
    }

    .post-image{
        width:100%;
        height: 200px;
    }

    .post-summary{
        width: 100%;
    }

    .two-column{
        flex-direction: column;
    }

    .two-column .column1{
        width: 100%;
    }

    .two-column .column2{
        width: 100%;
    } 
}

@media (max-width: 640px) {
    .blog-post .info, .blog-post h1, .blog-post h2, .blog-post h3, .blog-post p, .blog-post ul, .blog-post blockquote, .blog-post h4{
        max-width:450px;
    }

    .blog-post .post-image{
        min-height: 300px;
    }

    .two-column{
        max-width: 450px;
    }

    .blog-post blockquote p{
        font-size: 1.6rem;
        line-height: 2.2rem;
    }
}
@media (max-width: 480px) {

}